body {
	padding: 0;
	font-family: "Roboto";
	background-color: #fafafa
}

a {
	cursor: pointer;
}

.text-center {
	text-align: center
}

.text-right {
	text-align: right
}

.text-left {
	text-align: left
}

.ladiui-container {
	width: 1200px;
	margin: 0 auto
}

.ladiui-input-error {
	border: 1px solid #ff0000 !important;
}

.ladiui-input-message {
	max-width: 300px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px #c7c7c7;
	position: absolute;
	border-radius: 5px;
	font-size: 12px;
	line-height: 16px;
	padding: 5px 10px;
	z-index: 90000050;
	margin-top: 7px;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	opacity: 0;
	transition: opacity 50ms ease-out;
}

.ladiui-input-message:before {
	content: '';
	position: absolute;
	top: -7px;
	left: 10px;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #fff;
}

.ladiui.button {
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: top;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 25px;
	border-radius: 5px;
	-webkit-transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out, -webkit-box-shadow .15s ease-out;
	transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out, -webkit-box-shadow .15s ease-out;
	transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out, box-shadow .15s ease-out;
	transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out, box-shadow .15s ease-out, -webkit-box-shadow .15s ease-out
}

.ladiui.button.icon-c-add::before {
	content: "\ea04";
	font-family: "LadiIcons";
	font-size: 12px;
	padding-right: 5px;
	top: 1px;
	position: relative;
}

.ladiui.button>i {
	position: relative;
	top: 7%;
	font-size: 12px;
}

.ladiui.button.primary {
	color: #fff;
	background-color: #0C61F2;
	border-color: #0C61F2
}

.ladiui.button.outline-primary:active,
.ladiui.button.outline-primary:focus,
.ladiui.button.outline-primary:hover,
.ladiui.button.primary:active,
.ladiui.button.primary:focus,
.ladiui.button.primary:hover {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
}

.ladiui.button.secondary {
	background-color: transparent;
	border: 1px solid #ebedf2;
	color: #6c7293
}

.ladiui.button.outline-secondary:active,
.ladiui.button.outline-secondary:focus,
.ladiui.button.outline-secondary:hover,
.ladiui.button.secondary:active,
.ladiui.button.secondary:focus,
.ladiui.button.secondary:hover {
	color: #6c7293;
	border-color: #ebedf2;
	background-color: #f4f5f8
}

.ladiui.button.success {
	color: #fff;
	background-color: #1dc9b7;
	border-color: #1dc9b7
}

.ladiui.button.outline-success:active,
.ladiui.button.outline-success:focus,
.ladiui.button.outline-success:hover,
.ladiui.button.success:active,
.ladiui.button.success:focus,
.ladiui.button.success:hover {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
}

.ladiui.button.warning {
	color: #212529;
	background-color: #ffb822;
	border-color: #ffb822
}

.ladiui.button.outline-warning:active,
.ladiui.button.outline-warning:focus,
.ladiui.button.outline-warning:hover,
.ladiui.button.warning:active,
.ladiui.button.warning:focus,
.ladiui.button.warning:hover {
	color: #212529;
	background-color: #fbaa00;
	border-color: #eea200
}

.ladiui.button.danger {
	color: #fff;
	background-color: #F4511E;
	border-color: #F4511E
}

.ladiui.button.danger:active,
.ladiui.button.danger:focus,
.ladiui.button.danger:hover,
.ladiui.button.outline-danger:active,
.ladiui.button.outline-danger:focus,
.ladiui.button.outline-danger:hover {
	color: #fff;
	background-color: #F4511E;
	border-color: #F4511E
}

.ladiui.button.info {
	color: #fff;
	background-color: #5578eb;
	border-color: #5578eb
}

.ladiui.button.info:active,
.ladiui.button.info:focus,
.ladiui.button.info:hover,
.ladiui.button.outline-info:active,
.ladiui.button.outline-info:focus,
.ladiui.button.outline-info:hover {
	color: #fff;
	background-color: #0C61F2;
	border-color: #2754e6
}

.ladiui.button.sm {
	padding: 4px 10px;
	font-size: 14px
}

.ladiui.button.lg {
	padding: 8px 20px;
	font-size: 16px
}

.ladiui.button.icon i {
	position: relative;
	top: 0;
	font-size: 12px;
	padding-right: 5px;
}

.ladiui.button.rounded {
	border-radius: 20px
}

.ladiui.button.outline-primary {
	color: #0C61F2;
	border:1px solid #0C61F2;

}

.ladiui.button.outline-success {
	color: #18a899;
	border-color: #18a899
}

.ladiui.button.outline-secondary {
	color: #6c7293;
	border-color: #ebedf2
}

.ladiui.button.outline-warning {
	color: #fbaa00;
	border-color: #fbaa00
}

.ladiui.button.outline-info {
	color: #0C61F2;
	border-color: #0C61F2
}

.ladiui.button.outline-danger {
	color: #F4511E;
	border-color: #F4511E
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

.ladiui.table {
	border-radius: 4px;
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	font-size: 14px;
	color: #6c7293;
	width: 100%;
	background-color: #fff
}

.ladiui.table thead {
	border-bottom: 1px solid #efefef
}

.ladiui.table thead tr th {
	padding: 16px 15px;
	vertical-align: middle;
	color: #313942;
	font-weight: 500;
	font-size: 15px;
}

.ladiui.table tbody tr {
	border-bottom: 1px solid #efefef
}

.ladiui.table tbody tr:last-child {
	border-bottom: 0px;
}

.ladiui.table.hover tbody tr:hover {
	background: #fafafa
}

.ladiui.table tbody tr td {
	padding: 15px;
	vertical-align: middle
}

.ladiui.list.inline {
	margin: 0;
	padding: 0;
	display: inline-block
}

.ladiui.list.inline>li {
	display: inline-block
}

.ladiui.list.inline>li>a {
	padding: 10px
}

.ladiui.breadcrumbs {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 15px 0
}

.ladiui.breadcrumbs>li {
	display: inline-block;
	padding: 5px
}

.ladiui.breadcrumbs>li:first-child:before {
	content: '';
	margin: 0
}

.ladiui.breadcrumbs>li:before {
	font: normal normal normal 1em/1 "LadiIcons";
	content: '\ea21';
	margin-left: 0;
	margin-right: 15px;
	font-size: 8px;
	color: #8697a8
}

.ladiui.breadcrumbs>li,
.ladiui.breadcrumbs>li>a {
	color: #313942;
	font-size: 14px
}

.ladiui.breadcrumbs>li.active {
	font-weight: 700;
}

.ladiui-input-group {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%
}

.ladiui-input-group-prepend {
	margin-left: -1px
}

.ladiui-input-group .ladiui.input {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0
}

.ladiui-input-group-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px 12px;
	margin-bottom: 0;
	font-weight: 400;
	line-height: 26px;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #fff;
	border: 1px solid #ebedf2;
	border-radius: 4px;
	border-right: 0
}

.ladiui-input-group>.ladiui-input-group-append:last-child>.ladiui-input-group-text:not(:last-child),
.ladiui-input-group>.ladiui-input-group-append:not(:last-child)>.ladiui-input-group-text,
.ladiui-input-group>.ladiui-input-group-prepend>.ladiui-input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.ladiui-input-group>.ladiui.input:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.ladiui.input {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-weight: 400;
	height: 40px;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	border-radius: 4px;
	transition: border-color .15s ease-out;
}

.ladiui-label {
	color: #313942;
	padding-bottom: 6px;
	display: block
}

.ladiui.select {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-weight: 400;
	height: 40px;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	border-radius: 4px;
	transition: border-color .15s ease-out;
}

.ladiui.textarea {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-weight: 400;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	min-height: 120px;
	border-radius: 4px;
	-webkit-transition: border-color .15s ease-out, -webkit-box-shadow .15s ease-out;
	transition: border-color .15s ease-out, -webkit-box-shadow .15s ease-out;
	transition: border-color .15s ease-out, box-shadow .15s ease-out;
	transition: border-color .15s ease-out, box-shadow .15s ease-out, -webkit-box-shadow .15s ease-out
}

.ladiui.form-group {
	margin-bottom: 15px;
	width: 100%
}

.clearfix {
	clear: both
}

.ladiui.input.focus,
.ladiui.input:focus,
.ladiui.select.dropdown.focus,
.ladiui.textarea:focus {
	border-color: #0C61F2;
	outline: 0
}

.ladiui.checkbox {
	margin-bottom: 8px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inline-block;
	position: relative;
	background-color: #ebedf2;
	color: #666;
	height: 16px;
	width: 16px;
	border-radius: 3px;
	cursor: pointer;
	outline: 0;
	margin: 0
}

.ladiui.checkbox:checked::before {
	position: absolute;
	left: 5px;
	font-size: 11px;
	top: 0;
	content: '\02143';
	font-weight: 700;
	color: #fff;
	transform: rotate(40deg);
	line-height: 15px;
}

.ladiui.checkbox:hover {
	background-color: #f7f7f7
}

.ladiui.checkbox:checked {
	background-color: #0081ff
}

.ladiui.checkall {
	max-width: 40px
}

.ladiui.dropdown {
	cursor: pointer;
	position: relative;
	display: inline-block;
	outline: 0;
	text-align: left;
	-webkit-transition: width .1s ease, -webkit-box-shadow .1s ease;
	transition: width .1s ease, -webkit-box-shadow .1s ease;
	transition: box-shadow .1s ease, width .1s ease;
	transition: box-shadow .1s ease, width .1s ease, -webkit-box-shadow .1s ease;
	-webkit-tap-highlight-color: transparent
}

.ladiui.select.dropdown .menu {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	width: auto;
	outline: 0;
	margin: 2px -1px;
	border-radius: 0 0 .28571429rem .28571429rem;
	-webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	-webkit-transition: opacity .1s ease;
	transition: opacity .1s ease
}

.ladiui.dropdown>.text {
	display: inline-block;
	-webkit-transition: none;
	transition: none;
	padding: 6px 30px 6px 12px;
	width: 100%;
	z-index: 100;
	position: relative
}

.ladiui.select.dropdown {
	cursor: pointer;
	word-wrap: break-word;
	line-height: 1em;
	white-space: normal;
	outline: 0;
	left: 0;
	top: 1px;
	-webkit-transform: rotateZ(0);
	transform: rotateZ(0);
	background: #fff;
	display: inline-block;
	line-height: 27px;
	border: 1px solid #ebedf2;
	border-radius: .28571429rem;
	-webkit-transition: width .1s ease, -webkit-box-shadow .1s ease;
	transition: width .1s ease, -webkit-box-shadow .1s ease;
	transition: box-shadow .1s ease, width .1s ease;
	transition: box-shadow .1s ease, width .1s ease, -webkit-box-shadow .1s ease;
	position: relative
}

.ladiui.select.dropdown:after {
	content: "\ea46";
	width: 30px;
	height: 30px;
	position: absolute;
	font-family: "LadiIcons";
	top: 5px;
	right: -8px;
	font-size: 13px
}

.ladiui.dropdown .menu.open {
	display: block;
	left: 0;
	visibility: visible;
	opacity: 1;
	-webkit-transform: scaleY(1);
	-o-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: top;
	-o-transform-origin: top;
	-ms-transform-origin: top;
	transform-origin: top;
	-webkit-transition: -webkit-transform .4s ease-out;
	-o-transition: -o-transform .4s ease;
	-ms-transition: -ms-transform .4s ease;
	transition: transform .4s ease
}

.ladiui.dropdown .menu {
	cursor: auto;
	position: absolute;
	display: none;
	outline: 0;
	top: 100%;
	min-width: -webkit-max-content;
	min-width: -moz-max-content;
	min-width: max-content;
	margin: 0;
	padding: 0 0;
	background: #fff;
	font-size: 1em;
	text-shadow: none;
	text-align: left;
	-webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	border: 1px solid rgba(34, 36, 38, .15);
	border-radius: .28571429rem;
	-webkit-transition: opacity .1s ease;
	transition: opacity .1s ease;
	z-index: 11;
	will-change: transform, opacity;
	-webkit-transition: opacity .6s, visibility .6s;
	transition: opacity .6s, visibility .6s
}

.ladiui.dropdown .menu>.item {
	position: relative;
	cursor: pointer;
	display: block;
	border: none;
	height: auto;
	text-align: left;
	border-top: none;
	line-height: 1em;
	color: rgba(0, 0, 0, .87);
	padding: .78571429rem 1.14285714rem!important;
	font-size: 1rem;
	text-transform: none;
	font-weight: 400;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-touch-callout: none
}

.ladiui.input,
.ladiui.select {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	-webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	color: #313942
}

.ladiui.input.error,
.ladiui.select.error {
	border-color: #d50000;
}

.ladiui.fixed-sidebar .ladiui-aside {
	position: fixed;
	width: 200px;
	top: 57px;
}

.ladiui.fixed-sidebar .ladiui-main {
	width: calc(100% - 200px);
	float: right;
	padding-bottom: 40px;
}

 ::-webkit-scrollbar {
	width: 8px;
	background-color: #fff
}

 ::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: #fff
}

 ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: rgba(93,93,93,.79);
}

 ::-webkit-scrollbar-thumb:hover {
	background: rgba(93, 93, 93, .79)
}

.ladiui.datepicker {
	cursor: pointer;
	word-wrap: break-word;
	line-height: 1em;
	white-space: normal;
	outline: 0;
	left: 0;
	-webkit-transform: rotateZ(0);
	transform: rotateZ(0);
	background: #fff;
	display: inline-block;
	line-height: 25px;
	border: 1px solid #ebedf2;
	border-radius: .28571429rem;
	-webkit-transition: width .1s ease, -webkit-box-shadow .1s ease;
	transition: width .1s ease, -webkit-box-shadow .1s ease;
	transition: box-shadow .1s ease, width .1s ease;
	transition: box-shadow .1s ease, width .1s ease, -webkit-box-shadow .1s ease;
	position: relative;
	padding: 6px 30px 6px 12px
}

.ladiui.datepicker:after {
	content: "\ea6a";
	width: 30px;
	height: 30px;
	position: absolute;
	font-family: "LadiIcons";
	top: 5px;
	right: -5px;
	font-size: 16px
}

.ladiui.datepicker .date-value {
	color: #313942
}

.ladiui.dropdown-menu {
	position: relative;
	display: inline-block;
	background: #fff;
	border-radius: 5px;
	z-index: 1;
	top: 2px
}

.ladiui.dropdown-menu.rounded {
	border: 1px solid #ebedf2
}

.ladiui.dropdown-menu-item {
	color: #313942;
	height: 38px;
	padding: 0 12px;
	border: none;
	cursor: pointer;
	line-height: 38px
}

.ladiui.dropdown-menu-item i {
	font-size: 13px;
	margin-left: 4px
}

.ladiui.dropdown-submenu {
	display: none;
	position: absolute;
	border-radius: 5px;
	min-width: 160px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
	background: #fff;
	top: 43px
}

.ladiui.dropdown-submenu a {
	color: #313942;
	padding: 6px 12px;
	text-decoration: none;
	display: block;
	text-align: left;
	line-height: 34px
}

.ladiui.dropdown-submenu a:hover {
	background-color: #ebedf2;
	border: none
}

.ladiui-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	overflow: hidden;
	opacity: 1;
	transition: all 150ms linear;
	z-index: 15;
	outline: 0;
	display: none;
	background-color: rgba(0, 0, 0, 0.1);
}

.ladiui-modal-content {
	top: calc(5% - 5px);
	left: 0;
	right: 0;
	max-width: 900px;
	margin: 0 auto;
	box-shadow: 0 5px 100px rgba(6, 21, 40, .5);
	background: #fff;
	border-radius: 8px;
	z-index: 21;
	position: relative;
}

.ladiui-modal-header {
	padding: 15px 20px;
	border-bottom: 1px solid rgba(0, 0, 0, .02);
	background: rgba(6, 21, 40, .05);
	position: relative
}

.ladiui-modal-header i.icon-e-remove {
	font-size: 12px
}

.ladiui-modal-title {
	font-size: 16px;
	font-weight: 600
}

.ladiui-close-modal {
	position: absolute;
	top: 3px;
	right: 0;
	font-size: 18px;
	padding: 11px;
	cursor: pointer;
	opacity: 1;
	transition: opacity 150ms linear;
	background: 0 0;
	border: 0
}

.ladiui-modal-header h3 {
	margin: 0;
	font-size: 15px;
	font-weight: 600;
}

.ladiui-modal-nav {
	border-bottom: 1px solid rgba(6, 21, 40, .05);
	height: 55px;
	position: relative;
	padding-top: 10px
}

.ladiui-modal-container {
	padding: 20px;
	vertical-align: top;
	background: #fff;
	display: inline-block;
	width: calc(100% - 225px)
}

.ladiui-modal-footer {
	position: relative;
	bottom: 0;
	width: 100%;
	text-align: right;
	padding: 15px 15px;
	clear: both;
	border-top: 1px solid #ebedf2
}

.ladiui-modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(53, 53, 53, .16);
	z-index: 14;
	height: 100%;
	width: 100%
}

.visible {
	display: block!important;
}

.hide {
	display: none!important;
}

.ladiui-tabs {
	overflow: hidden;
	margin: 0;
	padding: 0
}

.ladiui-tabs li {
	float: left;
	list-style: none;
	padding: 10px 20px;
	border-radius: 10px 10px 0 0;
	margin-right: 5px;
	margin-top: 5px
}

.ladiui-tabs li {
	display: inline-block
}

.ladiui-tabs li.active a {
	color: #0C61F2;
	border-bottom: 2px solid #0C61F2;
	padding-bottom: 5px
}

.ladiui-tabs li a {
	color: #313942;
	text-decoration: none
}

.ladiui-tab-content {
	padding: 15px;
	/* overflow-y: auto;
	overflow-x: hidden; */
	max-height: 72vh
}

.ladiui-tab-item {
	display: none
}

.ladiui-tab-item.active {
	display: block
}

.tox .tox-notification {
	display: none!important
}

.ladiui-col-4 {
	width: 40%;
	float: left
}

.ladiui-col-8 {
	width: 60%;
	float: right
}

.ladiui-widget {
	border-radius: 3px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, .05), 0 1px 3px 0 rgba(63, 63, 68, .15);
	box-shadow: 0 0 0 1px rgba(63, 63, 68, .05), 0 1px 3px 0 rgba(63, 63, 68, .15)
}

.ladiui-widget .ladiui-widget-head {
	height: 40px;
	background: rgba(235, 240, 243, .1)
}

.ladiui-widget .ladiui-widget-head h3 {
	line-height: 40px;
	display: block;
	padding: 0;
	margin: 0;
	font-size: 15px;
	text-indent: 20px
}

.ladiui-widget-body ul {
	margin: 0;
	padding: 0
}

.ladiui-widget-body>ul>li:last-child {
	border-bottom: 0
}

.ladiui-widget-body>ul>li {
	border-bottom: 1px solid #ebeef0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

.ladiui-widget-body>ul>li>a {
	background: #fff;
	color: #313942;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	transition: none;
	display: block;
	padding: 10px 12px;
	font-size: 15px;
	font-weight: 500;
	line-height: 45px
}

.pull-right {
	float: right
}

.pull-left {
	float: left
}

.ladiui-form-group>.ladi-col {
	display: block
}

.ladiui-button-upload {
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin-top: 20px
}

.ladiui-button-upload input[type=file] {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 40px
}


/*------------NAVBAR--------------------*/

.ladiui-navbar {
	display: none;
	position: relative;
}

ul.ladiui-navbar.-horizontal,
ul.ladiui-navbar.-vertical {
	display: inline-block;
}

ul.ladiui-navbar.-floating {
	display: block;
	position: absolute;
}

.ladiui-navbar,
.ladiui-navbar li,
.ladiui-navbar li>ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.ladiui-navbar li {
	display: block;
	position: relative;
	white-space: nowrap;
	word-break: keep-all;
}

.ladiui-navbar.-horizontal>li {
	display: inline-block;
	float: left;
}

.ladiui-navbar li>* {
	display: block;
	position: relative;
}

.ladiui-navbar li>ul {
	position: absolute;
	min-width: 100%;
	top: 0px;
	left: 100%;
}

.ladiui-navbar.-horizontal.-alignRight li>ul {
	left: auto;
	right: 100%;
}

.ladiui-navbar.-horizontal.-alignRight>li>ul {
	right: 0px;
}

.ladiui-navbar.-horizontal>li>ul {
	top: auto;
	left: auto;
}


/* Menu behaviour */

.ladiui-navbar li>ul,
.ladiui-navbar.-floating {
	display: none;
}

.ladiui-navbar li>ul.-visible,
ul.ladiui-navbar.-floating.-visible {
	display: block;
}


/* Menu animation */

.ladiui-navbar li>ul,
.ladiui-navbar.-horizontal.-alignRight li>ul,
.ladiui-navbar.-floating {
	opacity: 1;
	transform: scale(1) translateY(0px);
	transform-origin: left top;
}

.ladiui-navbar.-alignRight li>ul,
.ladiui-navbar.-floating.-alignRight {
	transform-origin: right top;
}

.ladiui-navbar li>ul.-animating,
.ladiui-navbar.-floating.-animating {
	opacity: 0 !important;
	transform: scale(0.96) translateX(-16px);
}

.ladiui-navbar li>ul.-animating {
	z-index: -1 !important;
}

.ladiui-navbar.-horizontal>li>ul.-animating {
	transform: scale(0.96) translateY(-16px);
}

.ladiui-navbar.-alignRight li>ul.-animating,
.ladiui-navbar.-floating.-alignRight.-animating {
	transform: scale(0.96) translateX(16px);
}

.ladiui-navbar.-horizontal.-alignRight>li>ul.-animating {
	transform: scale(0.96) translateY(-16px);
}


/* Menu item icons */

.ladiui-navbar *[data-icon]:before {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	margin: auto 0px;
}

.ladiui-navbar .Icon,
.ladiui-navbar *[data-icon]:before {
	line-height: inherit;
}

.ladiui-navbar .Icon {
	padding: 0px;
}

.ladiui-navbar *:empty[data-icon] {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.ladiui-navbar.-horizontal,
.ladiui-navbar li,
.ladiui-navbar li> :first-child {}

.ladiui-navbar *[data-icon]:before,
.ladiui-navbar .icon {
	width: 40px;
	height: 40px;
}

.ladiui-navbar *[data-icon] {
	min-width: 40px;
	min-height: 40px;
	padding-left: 40px;
}


/* Default font settings for menu */

.ladiui-navbar {
	font-size: 16px;
}


/* Icon font sizes */

.ladiui-navbar *[data-icon]:before,
.ladiui-navbar .Icon {
	font-size: 24px;
}


/* For submenus */

.ladiui-navbar>li ul .Icon,
.ladiui-navbar.-floating .Icon,
.ladiui-navbar>li ul *[data-icon]:before,
.ladiui-navbar.-floating *[data-icon]:before {
	font-size: 18px;
}

.ladiui-navbar ul,
.ladiui-navbar.-floating {
	background: white;
}

.ladiui-navbar li>* {
	padding: 0px 12px;
}

.ladiui-navbar li li.ladiui-dropdown>a:after,
.ladiui-navbar:not(.-horizontal)>li.ladiui-dropdown>a:after {
	right: 12px;
}

.ladiui-navbar li li.ladiui-dropdown>a,
.ladiui-navbar:not(.-horizontal)>li.ladiui-dropdown>a {
	padding-right: 28px;
}

.ladiui-navbar.-horizontal li>ul,
.ladiui-navbar.-floating {
	min-width: 120px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
	background: #fff;
	border-radius: 4px;
	margin-top: 4px;
	z-index: 1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ladiui-navbar li>ul,
.ladiui-navbar.-floating {
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui-navbar li>a {
	text-decoration: none;
	color: inherit;
	padding: 0px 12px;
	line-height: 40px;
}

.ladiui-navbar li>a:first-child {
	transition: background-color 0.2s;
}

.ladiui-navbar.-horizontal li>ul>li:hover>a {
	background-color: #f8fafb;
}

.ladiui-navbar li:hover>a:first-child,
.ladiui-navbar li.-active>a:first-child {
	background-color: transparent;
}

.ladiui-select-options {
	height: 40px;
	border-radius: 4px;
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	-webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	color: #313942;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ebedf2;
	position: relative;
	min-width: 90px;
	z-index: 5;
}

.ladiui-select-options>ul {
	display: none;
	position: absolute;
	top: 41px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
	background: #fff;
	border-radius: 4px;
	z-index: 5;
	min-width: 90px;
	width: 100%;
}

.ladiui-select-options>.ladiui-select-init {
	display: block;
	cursor: pointer;
	width: 100%;
	text-indent: 15px;
	border-radius: 4px;
	padding-right: 25px;
	line-height: 38px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ladiui-select-options>.ladiui-select-init:hover,
.ladiui-select-options>.ladiui-select-init:focus,
.ladiui-select-options:hover,
.ladiui-select-options:focus {
	border-color: #0C61F2;
	outline: 0;
	transition: border-color .15s ease-out;
}

.ladiui-select-options>.ladiui-select-init:after {
	content: '\ea46';
	font-family: "LadiIcons";
	font-size: 12px;
	top: 0px;
	right: 10px;
	position: absolute;
	z-index: -1;
}

.ladiui-dropdown>a {
	position: relative;
}

.ladiui-select-options>ul {
	padding: 0px;
	margin: 0px;
}

.ladiui-select-options>ul>li {
	list-style: none;
	cursor: pointer;
}

.ladiui-select-options>ul>li>a {
	cursor: pointer;
	display: block;
	color: #313942;
	width: 100%;
	padding: 0px 12px;
	line-height: 40px;
}

.ladiui-select-options>ul>li>a:hover {
	background: #f8fafb;
}

.ladiui-tags {
	max-width: 600px;
	border: 1px solid #ebedf2;
	border-radius: 4px;
	min-height: 40px;
}

.ladiui-tags .tag {
	position: relative;
	display: block;
	float: left;
	color: #FFFFFF;
	background: #0C61F2;
	padding: 8px 18px 8px 12px;
	margin: 4px;
	border-radius: 2px;
	font-size: 12px;
	transition: all 0.3s ease-out;
}

.ladiui-tags .tag .close {
	position: absolute;
	top: 0px;
	right: 0;
	width: 14px;
	height: 100%;
	background: #0C61F2;
	line-height: 20px;
	cursor: pointer;
	border-radius: 0 2px 2px 0;
	transition: background 0.3s;
}

.ladiui-tags .tag .close:after {
	position: absolute;
	content: "×";
	top: 6px;
	left: 3px;
	font-weight: 900;
}

.ladiui-tags .tag .close:hover {}

.ladiui-tags input {
	border: 0;
	width: auto;
	outline: none;
	padding: 5px 6px;
	line-height: 29px;
}

.ladiui-tag-list {
	float: left;
}

.ladiui-toastr {
	visibility: hidden;
	/* Hidden by default. Visible on click */
	min-width: 250px;
	/* Set a default minimum width */
	margin-left: -125px;
	/* Divide value of min-width by 2 */
	background-color: #333;
	/* Black background color */
	color: #fff;
	/* White text color */
	text-align: center;
	/* Centered text */
	border-radius: 2px;
	/* Rounded borders */
	padding: 16px;
	/* Padding */
	position: fixed;
	/* Sit on top of the screen */
	z-index: 1;
	/* Add a z-index if needed */
	left: 50%;
	/* Center the snackbar */
	bottom: 30px;
	/* 30px from the bottom */
}


/* Show the snackbar when clicking on a button (class added with JavaScript) */

.ladiui-toastr.show {
	visibility: visible;
	/* Show the snackbar */
	/* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
    However, delay the fade out process for 2.5 seconds */
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}


/* Animations to fade the snackbar in and out */

@-webkit-keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}

#ladiui-toast-container {
	position: fixed;
	z-index: 99;
	border-radius: 5px;
}

#ladiui-toast-container.toast-top-right {
	top: 10px;
	right: 10px;
}

.ladiui-toast.toast-error {
	background: #bd362f;
}

.ladiui-toast.toast-warning {
	background: #f89406;
}

.ladiui-toast.toast-success {
	background: #51a351;
}

.ladiui-toast.toast-info {
	background: #2f96b4;
}

.ladiui-toast.toast-info,
.ladiui-toast.toast-success,
.ladiui-toast.toast-warning,
.ladiui-toast.toast-error {
	margin: 0 0 6px;
	padding: 15px 15px 15px 50px;
	width: 300px;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
	background-position: 15px center;
	background-repeat: no-repeat;
	-moz-box-shadow: 0 0 12px #999999;
	-webkit-box-shadow: 0 0 12px #999999;
	box-shadow: 0 0 12px #999999;
	color: #ffffff;
	opacity: 0.8;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
	filter: alpha(opacity=80);
}

.ladiui-toast-title {
	font-weight: bold;
}

.ladiui-toast-message {
	-ms-word-wrap: break-word;
	word-wrap: break-word;
}

.col-50 {
	width: 48%;
	float: left;
	margin: 0px 15px;
}

.ladiui-row>.col-50:first-child {
	margin-left: 0px;
}

.ladiui-row>.col-50:last-child {
	margin-right: 0px;
	float: right
}

.ladiui-alert.error {
	font-size: 12px;
	color: #d50000;
	margin-top: 2px;
}

.ladiui-alert.error::before {
	content: '*';
	color: #d50000;
	padding-right: 5px;
}

.ladiui-spinner {
	margin: 2px auto 0;
	width: 70px;
	text-align: center;
}

.ladiui-spinner>div {
	width: 12px;
	height: 12px;
	background-color: #0C61F2;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.8s infinite ease-out both;
	animation: sk-bouncedelay 1.8s infinite ease-out both;
}

.ladiui-spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.ladiui-spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.ladiui-search-dropdown {
	border-radius: 4px;
	position: relative;
	min-width: 200px;
	height: 40px;
	user-select: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.ladiui-search-dropdown>input.ladiui-search-input {
	padding: 0px 15px;
	border: 1px solid #ebedf2;
	border-radius: 4px;
	position: absolute;
	z-index: 2;
	background: #fff;
	cursor: pointer;
	color: #313942;
	top: 0;
	left: 0px;
	width: 100%;
	outline: 0;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ladiui-search-dropdown>.text {
	display: inline-block;
	-webkit-transition: none;
	transition: none;
	cursor: text;
	position: relative;
	left: 3px;
	z-index: 3;
	line-height: 40px;
	pointer-events: none;
	font-weight: 400;
	color: #313942;
	width: 100%;
	padding: 0px 15px;
}

.ladiui-search-dropdown>.text.open {
	color: #b3b3b3!important;
	font-weight: 300;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
	border-color: #96c8da;
	-webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
	position: absolute;
	top: 42px;
	left: 0px;
	border-radius: 4px;
	display: none;
	background: #fff;
	height: max-content;
	max-height: 300px;
	overflow-y: scroll;
	width: 100%;
	z-index: 10;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu>.item {
	position: relative;
	cursor: pointer;
	white-space: normal;
	word-wrap: normal;
	display: block;
	height: auto;
	padding: 10px 15px;
	text-align: left;
	border-top: none;
	color: rgba(0, 0, 0, .87);
	font-weight: 400;
	box-shadow: none;
	background: #fff;
	line-height: 24px;
	border-bottom: 1px solid #f8fafb;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu>.item:hover {
	background: #f8fafb;
}

.ladiui-search-dropdown>span {
	display: block;
	z-index: 9999;
	position: absolute;
	right: 0px;
	padding-right: 10px;
	line-height: 40px;
	top: -3px;
	cursor: pointer;
}

.ladiui-search-dropdown>span {
	position: relative;
}


.ladiui-search-dropdown>span::after {
	display: block;
	z-index: 2;
	position: absolute;
	right: 0px;
	padding-right: 10px;
	line-height: 40px;
	top: 4px;
	cursor: pointer;
}

.ladiui-search-dropdown::after {
	content: '\ea46';
	font-family: "LadiIcons";
	font-size: 12px;
	display: block;
	position: absolute;
	right: 0px;
	padding-right: 10px;
	line-height: 40px;
	top: 0px;
	cursor: pointer;
	z-index: 2;
}


/*---------------LADIUI CARD-----------------*/

.ladiui.card {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
	background-color: #ffffff;
	border-radius: 4px;
}

.ladiui.card .card-header {
	padding: 15px 20px;
	border-bottom: 1px solid #eaedf0;
	background: rgba(169, 194, 209, 0);
	;
	position: relative;
}

.ladiui.card .card-header h3 {
	padding: 0px;
	margin: 0px;
	font-size: 15px;
	font-weight: 500;
}

.flex {
	display: flex;
}

.ladiui-row>.col {
	width: 100%;
	margin: 15px;
}

.ladiui-row>.col:first-child {
	margin-left: 0px;
}

.ladiui-row>.col:last-child {
	margin-right: 0px;
}

.fluid {
	padding: 0px!important;
	margin: 0px;
}

.button.loader::before {
	margin: 0px auto;
	position: relative;
	border-top: 2px solid rgba(255, 255, 255, 0.2);
	border-right: 2px solid rgba(255, 255, 255, 0.2);
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
	border-left: 2px solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
	display: inline-block;
	content: ""!important;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	margin-right: 5px;
	padding-right: 0px!important;
	top: 5px!important;
	left: 0px;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
}

[type="radio"]:not(:checked)+label:before{
content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
    color: #fff;
}

[type="radio"]:checked+label:before{
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: 18px;
    height: 18px;
    border: 1px solid #2977dd;
    border-radius: 100%;
    background: #2977dd;
    color: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    background: #fff;
    top: 6px;
    left: 7px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

[type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.loader {
	position: relative;
}

.loader:after {
	content: '';
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 200;
}

.loader::before {
	margin: 0px auto;
	position: absolute;
	border-top: 3px solid #0C61F2;
	border-right: 3px solid #0C61F2;
	border-bottom: 3px solid #0C61F2;
	border-left: 3px solid #9fa8c4;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
	display: inline-block;
	content: ""!important;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	top: calc(50% - 20px);
	left: calc(50% - 20px);
	z-index: 10;
}

body.loader::before {
	top: calc(50vh - 20px);
	position: fixed;
	z-index: 100;
}

.ladiui-modal-body {
	overflow: scroll;
	padding: 15px 20px;
}

.ladiui-modal-content.confirm {
	max-width: 400px;
}

.ladiui-modal-content.confirm .button {
	min-width: 90px;
}

.ladiui-modal-content.confirm .ladiui-modal-footer {
	border-top: 0px;
}

.ladiui-table-responsive {
	overflow-x: scroll;
}

.ladiui-page-loader .background {
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: placeHolderShimmer;
	-webkit-animation-timing-function: linear;
	background: #f6f7f9;
	background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	height: 104px;
	position: relative;
	margin-bottom: 15px;
}

.ladiui-page-loader {
	padding: 12px;
	max-width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 3px;
	padding-bottom: 20px;
}

.ladiui-page-loader .background div {
	background: #fff;
	height: 6px;
	left: 0;
	position: absolute;
	right: 0;
}

.ladiui-page-loader div.a {
	height: 40px;
	left: 40px;
	right: auto;
	top: 0;
	width: 8px;
}

.ladiui-page-loader div.b {
	height: 8px;
	left: 48px;
	top: 0;
}

.ladiui-page-loader div.c {
	left: 90%;
	top: 8px;
}

.ladiui-page-loader div.d {
	height: 12px;
	left: 48px;
	top: 14px;
}

.ladiui-page-loader div.e {
	left: 95%;
	top: 26px;
}

.ladiui-page-loader div.f {
	height: 10px;
	left: 48px;
	top: 32px;
}

.ladiui-page-loader div.g {
	height: 20px;
	top: 40px;
}

.ladiui-page-loader div.h {
	left: 85%;
	top: 60px;
}

.ladiui-page-loader div.i {
	height: 13px;
	top: 66px;
}

.ladiui-page-loader div.j {
	left: 440px;
	top: 79px;
}

.ladiui-page-loader div.k {
	height: 13px;
	top: 85px;
}

.ladiui-page-loader div.l {
	left: 178px;
	top: 98px;
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.ladiui.icon::before{
	font-family: "LadiIcons";
	font-size: 12px;
	padding-right: 5px;
	top: 1px;
	position: relative;
}

.ladiui-link{
	color:#0C61F2!important;
}

.ladiui-link:hover{
	text-decoration: underline;
}
.no-shadow {
	box-shadow: none;
	-webkit-box-shadow: none;
}

::placeholder {
  color: #b3b3b3;
  opacity: 1; 
  font-weight: 300;
}

:-ms-input-placeholder {
 color: #b3b3b3;
 font-weight: 300;
}

::-ms-input-placeholder {
 color: #b3b3b3;
 font-weight: 300;
}