body {
	color: #313942;
}

.ladiui-wrapper {
	background-color: #f8fafb;
}

.ladiui-aside {
	width: 200px;
	height: 100%;
	position: relative;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}


/*-------------MINI NAVBAR----------------*/

body.mini-navbar .ladiui-aside {
	width: 90px!important;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

body.mini-navbar .ladiui-main {
	width: calc(100% - 90px)!important;
}

body.mini-navbar .ladiui.nav>.menu>li>a>i {
	font-size: 18px;
	margin: 0px auto;
	position: initial;
	border-radius: 50%;
	line-height: 29px;
	width: 40px;
	height: 40px;
}

body.mini-navbar .ladiui.nav>.menu>li>a {
	text-align: center;
	padding: 10px 6px;
	line-height: 20px;
	font-size: 14px;
	display: grid;
	width: 100%;
}

body.mini-navbar .ladiui.setting-bottom a {
	padding: 14px!important;
	display: grid!important;
	text-align: center;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu {
	position: absolute;
	left: 98px;
	top: 0px;
	border-radius: 4px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
	display: none;
	background: #fff;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu.show {
	display: block;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu>li {
	list-style: none;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu>li>a {
	white-space: nowrap;
	padding: 10px 20px;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu>li>a::before {
	left: 7px!important;
	display: none;
}

body.mini-navbar .ladiui-nav-toggle {
	width: 91px!important;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}


/*-----------END MINI NAVBAR---------------------*/

.ladiui-logo {
	text-align: left;
	height: 50px;
	line-height: 46px;
	width: 200px;
	float: left;
}

.ladiui-logo img {
	width: 60%;
	vertical-align: middle;
	display: inline-block;
}

.ladiui-main {
	width: 100%;
	float: right;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui-topbar {
	height: 50px;
    background-color: #0C61F2;
    border-bottom: 1px solid #eaedf0;
    background: linear-gradient(90deg, rgba(12,97,242,1), rgba(0,65,179,1));
    display: flex;
}

.mini-navbar .ladiui-nav-toggle i{
	margin-left: 0px;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}
.mini-navbar .ladiui-logo{
	margin-left: 18px;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}
.ladiui-nav-toggle {
	width: 48px;
	float: left;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.ladiui-nav-toggle i {
	font-size: 18px;
	margin-top: 8px;
	color: #fff;
	display: inline-block;
	cursor: pointer;
	border-radius: 50%;
	overflow: hidden;
	padding: 7px;
}

.ladiui-topbar .topbar-left {
	width: 40%;
	float: left;
}

.ladiui-topbar .topbar-right {
	width: 60%;
	float: right;
	text-align: right;
	margin-right: 15px;
}

.ladiui.topbar-searchbox {
	position: relative;
	display: inline-block;
	height: 50px;
	line-height: 49px;
	margin-left: 30px;
}

.ladiui.topbar-searchbox>i {
	position: absolute;
	top: 17px;
	left: 12px;
	color: #8697a8;
}

.ladiui.topbar-search {
	display: inline-block;
	width: 290px;
	height: 36px;
	padding: 0px;
	text-indent: 40px;
	color: #8697a8;
	font-size: 14px;
	background-color: #f8fafb;
	box-shadow: none;
	-webkit-box-shadow: none;
}

.ladiui.topbar-search::before {
	content: "";
	display: inline-block;
	width: 60px;
	height: 60px;
	background: red;
}

.topbar-profile {
	font-size: 15px;
	height: 50px;
	line-height: 45px;
	float: right;
	margin-left: 15px;
}

.topbar-profile a {
	text-decoration: none;
	color: #44566c;
	vertical-align: middle;
	display: block;
}
.topbar-profile li.ladiui-dropdown ul>li>a{
	transition: background-color .15s ease-out;
}
.topbar-profile li.ladiui-dropdown ul>li>a:hover {
	background-color: #f6f7f9!important;
}

.topbar-profile .ladiui.avatar {
	background-color: #1a73e8;
	color: white;
	width: 35px;
	height: 35px;
	display: inline-flex;
	border-radius: 50%;
	text-align: center;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	margin-top: -7px;
	margin-right: 0px;
	background-size: contain !important;
}

.topbar-profile .ladiui-navbar>li>ul {
	position: absolute;
	right: 0px;
}
.ladiui-topbar .ladiui-navbar>li>ul{
	min-width: 290px;
}

.ladiui-topbar .ladiui-navbar>li>ul>li>a{
	padding: 2px 20px;
}
.topbar-profile .ladiui-navbar>li>ul::before{
	content:'';
}

.topbar-profile .ladiui-navbar>li>ul>li:last-child>a.notification-item {
	border-bottom: 0px;
}

.notifications {
	z-index: 5;
}

.notifications>li>ul {
	padding: 0px!important;
}

.notifications .notification-item:hover {
	background: #ebedf2!important;
}

.notifications .notification-item {
	display: -webkit-inline-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #f7f8fa;
	-webkit-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	height: auto;
	line-height: 28px;
}

.notifications .has-message {
	position: relative;
}

.notifications .has-message::after {
	position: absolute;
	width: 5px;
	height: 5px;
	background: red;
	content: "";
	border-radius: 50%;
	top: 1px;
	right: 0px;
}

.notifications .notification-item .notification-time {
	font-size: 12px;
	float: left;
	height: 28px;
	line-height: 28px;
	width: 100%;
	text-align: left;
}

.notifications .notification-title {
	font-size: 14px;
}

.notifications .notification-item .notification-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 2.5rem;
	flex: 0 0 2.5rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.notifications .notification-item .notification-details {
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
}

.notifications .notification-item .notification-icon i {
	font-size: 30px;
	color: #5d78ff;
}

.notifications .notification-item .notification-details .notification-item-title {
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
	font-size: 1rem;
	font-weight: 400;
	color: #6c7293;
}

.notifications .notification-item .notification-details .notification-item-time {
	font-size: 1rem;
	font-weight: 300;
	color: #a7abc3;
}

.topbar-profile .ladiui-navbar.-horizontal>li {
	line-height: 55px;
	text-align: left;
}

.topbar-profile .ladiui-navbar.-horizontal>li:first-child{
	padding-left: 12px;
}

.app-tools li a{
	font-size: 15px;
}
.app-tools li i, .topbar-profile .ladiui-navbar.-horizontal>li>ul>li>a>i{
	font-size: 14px;
	margin-right: 6px;
	vertical-align: middle;
}
.topbar-profile .ladiui-navbar.-horizontal>li>a {
	height: 50px;
	line-height: 53px;
	color: #fff;
	font-size: 15px;
}

.topbar-profile .ladiui-navbar li:hover>a:first-child,
.topbar-profile .ladiui-navbar li.-active>a:first-child {
	background: transparent;
}

.ladiui-store-list li a i {
	font-size: 30px;
}

.topbar-tools .ladiui.list {
	line-height: 50px;
	height: 50px;
}

.topbar-tools .ladiui.list>li>a {
	text-decoration: none;
	color: #44566c;
	vertical-align: middle;
	display: block;
	padding: 0px 10px;
}

.topbar-right>div {
	display: -webkit-inline-box;
}

.app-packages span {
	display: inline-block;
	margin: 0px 20px;
	color: #fff;
}

.app-packages {
	padding-right: 24px;
	border-right: 1px solid #335de7;
	line-height: 49px;
}

.app-packages .upgrade {
	display: inline-block;
	margin-top: 7px;
}

.ladiui-content {
	padding: 10px 30px 30px 20px;
}

.ladiui-table-actions {
	margin-bottom: 24px;
	position: relative;
	z-index: 1;
	min-height: 40px;
}

.ladiui.search-actions {
	float: left;
	line-height: 39px;
}

.ladiui.actions {
	float: right;
}

.ladiui.actions>button,
.ladiui.actions>div {
	margin: 0px 6px;
}

.ladiui.actions>button,
.ladiui.actions>div:last-child {
	margin-right: 0px;
}

.ladiui.search-keyword {
	width: 290px;
	position: relative;
}

.ladiui.search-keyword .ladiui.input {
	text-indent: 5px;
	padding-right: 35px;
}

.ladiui.search-keyword i {
	position: absolute;
	top: 0px;
	color: #8697a8;
	right: 0px;
	cursor: pointer;
	padding: 12px;
}

.ladiui.nav>.menu>li {
	list-style: none;
	position: relative;
}

.ladiui.menu {
	margin: 0px;
	padding: 0px;
}

.ladiui.nav>.menu>li>a {
	display: inline-flex;
	position: relative;
	cursor: pointer;
	padding: 10px;
	width: 100%;
	font-size: 16px;
	color: #44566c;
	font-style: normal;
	font-stretch: normal;
	line-height: 31px;
	letter-spacing: normal;
	border-radius: 0px 30px 30px 0px;
	transition: color .6s ease-out;
}

.ladiui.nav>.menu>li>a>i {
	margin-right: 7px;
	font-size: 17px;
	border-radius: 50%;
	padding: 6px;
}
.ladiui.nav>.menu>li>a:hover{
	color:#0C61F2;
}

.ladiui.nav>.menu>li>a:hover,
.ladiui.nav>.menu>li>a:focus,
.ladiui.nav>.menu>li>a:active {
	background-color: #f8fafb;
}

.ladiui.submenu {
	background-color: #f8fafb;
	display: none;
	padding: 0px;
}

.ladiui.nav>.menu>li.open>a {
	background: #e8f0fe;
	color: #1A73E8;
}

.ladiui.nav>.menu>li.open>a>i {
	border-color: #1A73E8;
}

.ladiui.nav>.menu>li.open>.ladiui.submenu {
	display: block;
	padding: 0px;
	transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui.submenu>li {
	list-style: none;
}

.ladiui.submenu>li>a {
	color: #44566c;
	padding: 10px 0px 10px 45px;
	display: block;
	position: relative;
}

.ladiui.submenu>li>a:hover {
	color: #1A73E8;
}

.ladiui.nav>.menu>li>a>.ladiui.pill {
	background: #ff3d57;
	font-size: 10px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	border-radius: 10px;
	padding: 3px 5px;
	position: absolute;
	right: 18px;
}

.ladiui.pagination-block {
	width: 100%;
	margin-top: 20px;
}

.ladiui-search-box {
	background: #fff;
	padding: 15px;
	border: 1px solid #f0f0f0;
	border-radius: 10px;
	z-index: 0;
	margin-bottom: 24px;
	display: flex;
}

.ladiui.showing {
	width: 300px;
	color: #8697a8;
	font-size: 14px;
	float: left;
}

.ladiui.showing-total {
	width: 300px;
	color: #8697a8;
	font-size: 14px;
	float: right;
	line-height: 40px;
	text-align: right;
}

.ladiui.pagination {
	width: 50%;
	float: right;
	text-align: right;
}

.ladiui.pagination>ul {
	margin: 0px;
	padding: 3px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
	font-size: 14px;
	display: inline-block;
}

.ladiui.pagination>ul>li {
	display: inline-block;
	margin: 2px;
}

.ladiui.pagination>ul>li>a {
	text-decoration: none;
	width: 30px;
	line-height: 27px;
	display: block;
	padding-top: 2px;
	text-align: center;
	color: #44566c;
	border-radius: 3px;
}

.ladiui.pagination>ul>li.disabled>a {
	cursor: not-allowed;
	color: #c7c6c6 !important;
	background-color: #fff !important;;
}

.ladiui.pagination>ul>li:first-child>a {
	border-radius: 5px 0px 0px 5px;
}

.ladiui.pagination>ul>li:last-child>a {
	border-radius: 0px 5px 5px 0px;
}

.ladiui.pagination>ul>li.active>a,
.ladiui.pagination>ul>li>a.active,
.ladiui.pagination>ul>li>a:active,
.ladiui.pagination>ul>li>a:hover {
	color: #fff;
	background-color: #1A73E8;
}

.ladiui.pagination>ul>li>a>i {
	font-size: 12px;
}

.ladiui.setting-bottom {
	position: absolute;
	bottom: 48px;
	width: 100%;
	background: #fafafa;
}

.ladiui.setting-bottom a {
	padding: 15px 23px;
	color: #44566c;
	display: block;
	font-size: 12px;
}

.ladiui.data-limit {
	float: left;
}

.ladiui.data-limit>span {
	display: block;
	float: left;
	margin-right: 10px;
}

.ladiui.per-page {
	width: 85px;
	float: right;
}

.ladiui.form-filter>div:first-child {
	margin-left: 0px;
}

.ladiui.form-filter>div {
	display: inline-block;
	margin: 0px 10px;
}

.ladiui.select.per-page {
	min-width: 75px;
}

.ladiui-modal-body .ladiui-tabs {
	border-bottom: 1px solid rgba(6, 21, 40, .05);
}

.ladiui-tabs li.active a {
	padding-bottom: 7px;
}

.group-prices>div:last-child {
	margin-right: 0px;
}

.group-prices>div {
	width: 30.1%;
	margin-right: 20px;
	display: inline-block;
}

.product-image-preview {
	width: 100%;
	height: 306px;
	background: #fafafa;
	border: 1px solid #ebedf2;
}

.slider-products .item {
	margin-top: 15px;
	width: 70px;
	height: 70px;
	background: #fafafa;
	text-align: center;
	line-height: 70px;
	float: left;
}

.slider-products .owl-nav {
	position: absolute;
	width: 100%;
	top: 20%;
}

.slider-products .owl-nav .owl-prev {
	position: absolute;
	left: 0px;
}

.slider-products .owl-nav .owl-next {
	position: absolute;
	right: 0px;
}

.slider-products .owl-nav .owl-prev span,
.slider-products .owl-nav .owl-next span {
	font-size: 30px;
}

.slider-products .owl-nav [class*=owl-]:hover {
	background: transparent;
}

.slider-products .owl-nav [class*=owl-]:hover span {
	color: #1A73E8;
}

.tox .tox-statusbar {
	display: none!important;
}

.margin-top-15 {
	display: inline-block;
	margin-top: 15px;
}

.btn {
	border: 2px solid gray;
	color: gray;
	background-color: white;
	padding: 8px 20px;
	border-radius: 8px;
	font-size: 20px;
	font-weight: bold;
}

.fixed {
	position: fixed;
}

.ladiui-product-left {
	width: 310px;
	float: left;
}

.ladiui-product-right {
	width: 500px;
	float: right;
	margin-left: 20px;
}

.ladiui-modal-footer>.ladiui.button {
	margin: 0px 5px;
}

.variant-list {
	overflow-y: scroll;
	border-top: 1px solid #dfe3e8;
	max-height: 263px;
}

.aspect-ratio {
	position: relative;
	float: left;
	border: 1px solid rgba(195, 207, 216, 0.3);
	text-align: center;
	background: #fafafa;
}

.variant-list .aspect-ratio {
	width: 40px;
	height: 40px;
	margin-right: 15px;
}

.aspect-ratio::before {
	z-index: 1;
	content: "\ea38";
	position: absolute;
	top: -3px;
	right: 0;
	bottom: 0;
	font-family: "LadiIcons";
	left: 0;
	font-size: 26px;
	color: #dedede;
	;
}

.ladiui-widget-body ul li a:hover,
.ladiui-widget-body ul li a.active {
	color: #1A73E8;
}

.ladiui-col-3 {
	width: 30%;
}

.ladiui-col-7 {
	width: 65%;
}

#tab-variants .ladiui-form-group>.ladiui-col {
	width: 31%;
	float: left;
	margin-right: 19px;
	margin-bottom: 20px;
}

.variant-options {
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: none;
}

.variant-options .ladiui-widget-body {
	padding: 15px 0px;
	overflow: hidden;
	border-bottom: 1px solid #f2f3f4;
}

.ladiui.group-prices {
	clear: both;
}

.form-tags .select2-selection__rendered {
	width: 497px!important;
}

.variant-option-list {
	margin-top: 10px;
}

.variant-option-item {
	height: 55px;
}

.variant-option-item>div {
	display: inline-block;
	float: left;
}

.variant-option-item>div:last-child {
	float: right;
	height: 40px;
	line-height: 40px;
}

.ladiui-form-group.variant-option-head {
	margin-top: 20px;
	margin-bottom: 10px;
}

.variant-option-item .variant-option-name {
	width: 120px;
}

.new-variant-option {
	color: inherit;
	display: block;
	margin-top: 15px;
}

.variant-option-item .variant-option-value {
	width: 330px;
	margin-left: 20px;
}

.variant-option-item .variant-option-value .select2-search__field {
	width: 310px!important;
}

.variant-option-item .remove-variant-option {
	width: 20px;
}

.variant-option-item .remove-variant-option a {
	color: inherit;
}

.variant-option-head>span:first-child {
	width: 120px;
}

.variant-option-head>span:last-child {
	width: 350px;
	margin-left: 17px;
}

.variant-option-head>span {
	font-weight: 500;
	display: inline-block;
}

.order-stack-total {
	border-bottom: 0px!important;
}

.order-stack-total td {
	padding: 10px 4px;
}

.order-stack-total td strong {
	font-weight: 700;
	font-size: 18px;
}

.order-stack-total td:last-child {
	padding-right: 91px;
	width: 240px;
	text-align: right;
}

.order-customer {
	overflow: hidden;
	background: #fff;
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 20px;
	box-shadow: 0 0px 5px 0 rgba(0, 0, 0, .1);
}

.customer-info {
	position: relative;
	padding-bottom: 20px;
}

.customer-info>span {
	display: block;
	line-height: 40px;
}

.customer-info>span:first-child {
	margin-left: 0px;
}

.customer-info>span>i {
	border: 1px solid #eaedf0;
	border-radius: 50%;
	padding: 6px;
	position: relative;
	top: 1px;
	font-size: 12px;
}

.customer-info strong {
	font-weight: 500;
}

.created-at {
	display: block;
	position: absolute;
	top: -38px;
	right: 0px;
	color: inherit;
}

.payment-box:first-child {
	margin-left: 0px;
}

.payment-box {
	width: 30%;
	float: left;
	margin: 0px 15px;
}

.payment-box ul {
	padding: 0px;
	margin-top: 10px;
}

.payment-box ul li {
	line-height: 30px;
	list-style: none;
}

.payment-box .ladiui.select {
	background: #f8fafb;
}

.order-heading h3 {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 10px;
}

.payment-box .order-heading {
	margin-top: 15px;
}

.ladiui-order-left {
	width: 70%;
	float: left;
	position: relative;
}

.order-head-right .placed-on {
	margin-right: 30px;
}

.order-head-right .placed-on span {}

.order-head-right .placed-on span:first-child {
	font-weight: 500;
}

.order-head-right .placed-on span:last-child {}

.ladiui-order-right {
	width: 28%;
	float: right;
}

.ladi-card.order-products .ladiui.table {
	box-shadow: 0 0px 5px 0 rgba(0, 0, 0, .1);
}

@media (max-width: 1000px) {
	.ladiui-topbar .topbar-right {
		width: 900px;
	}
	.ladiui.actions {
		width: 100vh;
	}
}


/*****************24042019***************/

.ladiui-table-actions .action-item {
	display: block;
	float: left;
	margin-right: 20px;
}


/*--------------------- LOGIN -------------------*/

.ladiui.auth .auth-left {
    float: left;
    width: 50%;
}
.ladiui.auth .auth-right {
    width: 50%;
    float: right;
}
.ladiui.auth .auth-left iframe {
    width: 100%;
    border: 0px;
    height: 100vh;
}

.ladiui.auth {
	background: url(https://builder.ladipage.vn/images/geometry2.png) repeat;
	color: #44566c;
}

.ladiui.auth h1.form-title {
	margin: 0px 0px 20px 0px;
	padding: 0px;
	font-size: 21px;
	text-align: center;
	font-weight: 500;
	color: #44566c;
}

.ladiui.auth .ladiui-login {
	padding: 30px;
	float: none;
	width: 424px;
	background: #fff;
	margin: 30px auto;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
	display: block;
}

.ladiui.auth .ladiui-logo {
	width: 290px;
	padding: 40px;
	margin: 0px auto;
	border: 0px;
	float: none;
}

.ladiui.auth .ladiui-logo img {
	width: 100%;
}

.ladiui.auth .ladiui-login-form .ladiui-form-group {
	margin-bottom: 15px;
	position: relative;
}

.ladiui.auth .ladiui-login-form label {
	margin: 8px 0px;
	display: block;
}

.ladiui.auth .ladiui-login-form .button {
	width: 100%;
	padding: 15px;
	font-size: 15px;
}

.ladiui.auth .social-login {
	text-align: center;
}

.ladiui.auth .social-login a.login-facebook,
.ladiui.auth .social-login a.login-google {
	display: inline-block;
	width: 150px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 500;
	line-height: 0px;
	padding: 14px 10px;
	margin: 2px;
	line-height: 11px;
}

.ladiui.auth .social-login a.login-facebook {
	background: #4267b2;
	color: #fff;
}

.ladiui.auth .social-login>a.login-google:last-child {
	background: #d93025;
	color: #fff;
}

.ladiui.auth .forgot-password {
	float: right;
	text-align: right;
}

.ladiui.auth .forgot-password a {
	color: #6c7293;
	font-size: 14px;
}

.ladiui.auth .password-box {
	margin-bottom: 20px;
	overflow: hidden;
}

.ladiui.auth span.login-with {
	display: block;
    width: 100%;
    position: relative;
    border-top: 1px solid #ecedee;
    margin-top: 25px;
}

.ladiui.auth span.login-with span{
    margin: 0px auto;
    top: -12px;
    background: #fff;
    padding: 0px 5px;
    position: relative;
}
.ladiui.auth .question-form {
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.ladiui.auth .question-form a {
	color: inherit;
	font-weight: 600;
}

.ladiui.auth .ladipage-terms {
	text-align: center;
}

.ladiui.auth .ladipage-terms ul {
	margin: 0px;
	padding: 0px;
}

.ladiui.auth .ladipage-terms ul li {
	display: inline-block;
}

.ladiui.auth .ladipage-terms ul li a {
	color: inherit;
	margin: 0px 20px;
	font-size: 14px;
}

.ladiui.auth .ladipage-terms ul li a:hover {
	text-decoration: underline;
}

.ladiui.table .ladiui-navbar.-horizontal li>ul {
	right: 0px;
}

.ladiui.table thead tr th {
	position: relative;
}

.ladiui.table thead tr th.sorting {
	cursor: pointer;
}

.ladiui.table thead tr th.sorting.up::after {
	content: '\ea47';
	font-family: "LadiIcons";
	font-size: 10px;
	top: 20px;
	position: absolute;
	z-index: 0;
	color: #8697a8;
	padding-left: 6px;
}

.ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after {
	content: '\ea46';
	font-family: "LadiIcons";
	font-size: 8px;
	top: 21px;
	position: absolute;
	z-index: 0;
	color: #8697a8;
	padding-left: 6px;
}

.ladiui-topbar.fixed {
	position: fixed;
	width: 100%;
	left: 0px;
	z-index: 10;
}

.ladiui.fixed-sidebar .ladiui-aside {
	z-index: 11;
}

.ladiui-topbar .topbar-left {
	position: relative;
	left: 0px;
}

.ladiui-content {
	padding-top: 48px;
	display: grid;
	position: relative;
}

.ladiui-order-right .order-customer {
	position: relative;
}

.order-head-right {
	position: absolute;
	top: 70px;
	right: 33px;
}

.order-head-right a {
	color: inherit;
}

.order-customer-edit {
	display: block;
	position: absolute;
	top: 10px;
	right: 15px;
	color: #0763db;
}

.row-actions>li>a {
	line-height: inherit;
}

.page-title {
	font-size: 24px;
	font-weight: 500;
	padding: 26px 0px;
}

.pagination-block .per-page ul {
	bottom: 50px;
	top: inherit;
}
.ladiui-search-custom::after {
    content: '\ea41';
    font-family: "LadiIcons";
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}


.ladipage-account{
font-size: 14px;
}
.ladipage-account .ladipage-account-avatar{
	margin-top: 20px;
    width: 60px;
	float: left;
	margin-right: 35px;
}
.ladipage-account .ladipage-account-avatar img{
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.ladipage-account {
    padding: 0px 20px!important;
}

.ladipage-account-info>span{
	display: block;
	    line-height: 24px;
}
.ladipage-account-name{
	font-weight: 500;
}
.ladipage-account-email{
	font-size: 12px;
}

.ladipage-account-info {
    padding: 20px 0px;
}

.ladipage-account-signout{
	text-align: right;
}