.page-profile>div {
    display: block;
    padding-bottom: 30px;
    min-height: 460px;
    float: left;
    position: relative;
}

.profile-avatar {
    width: 150px;
    margin: 30px auto;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(169, 194, 209, 0.1);
}

.change-password {
    display: block;
    margin: 20px 0px;
}

.change-password a {
    color: #1a73e8;
}

.page-profile ul {
    margin: 0px;
    padding: 0px;
}

.page-profile ul li {
    list-style: none;
    line-height: 24px;
}

.points {
    margin-top: 30px;
}

.list-packages {
    padding: 15px;
}

.list-packages .package-item {
    background: #fafafa;
    padding: 10px;
    margin-bottom: 10px;
}

.title-date {
    display: block;
    height: 40px;
    margin: 10px;
}

.ladi-date-picker-icon {
    float: right;
    margin-right: 6px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
}

.ladi-profile-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.ladi-payment-info {
    height: 120px;
    width: 440px;
    resize: none;
}

.ladiui-table-actions {
    z-index: 6;
}

.ladiui-icon-name-action {
    z-index: 7;
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    background-color: rgba(126, 123, 123, 0.1);
    /* background-color: gray; */
    opacity: 30%;
    z-index: 900000080;
}



.button.outline-primary.loader::before {
    border-top: 2px solid #0c61f2;
    border-right: 2px solid #0c61f2;
    border-bottom: 2px solid #0c61f2;
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
}


.ladiui-topbar-noavatar {
    background: #0c61f2;
    color: white;
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    text-align: center;
    padding-top: 15%;
}

.ladiui-tr-warning {
    background-color: rgba(250, 177, 160, 0.5);
}

.ladiui-tr-done {
    background-color: rgba(255, 234, 167, 0.7);
}

.ladiui-tr-highlight {
    background-color: rgba(106, 137, 204, 0.5);
}

.ladiui-modal-content.loader::before {
    margin: 0px auto;
    position: absolute;
    border-top: 3px solid #0C61F2;
    border-right: 3px solid #0C61F2;
    border-bottom: 3px solid #0C61F2;
    border-left: 3px solid #9fa8c4;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    display: inline-block;
    content: "" !important;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    top: calc(60% - 20px);
    left: calc(50% - 20px);
    z-index: 1000;
}

.ladiui-modal-body {
    max-height: 75vh;
}

.ladiui.submenu>li>a.active {

    color: #0554d9;
}

.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ebedf2 !important;
    border-radius: 4px !important;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: #0b61f2 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}

.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
}

.ladiui-report-revenue p {
    margin: 10px 0px;

}

.ladiui.menu {
    height: 80vh;
    overflow: auto;
}

.app-name {
    margin-left: 10px;
    font-size: 17px;
    color: white;
    background: linear-gradient(90deg, #f22c29, #ff7b4a);
    padding: 0px 10px;
    border-radius: 10px;
}

.modal-userview .ladiui-modal-content {
    min-height: 400px;
    max-width: 1150px;

}

#tool-buttons .action-item {
    margin-bottom: 12px;
}

#tool-buttons {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 16px;
    gap: 16px;
}

.ladiui.icon-ldp-close {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-close.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-close.svg) no-repeat 50% 50%;
}

.ladiui.icon-ldp-close {
    background-color: #6d6d6d;
    box-sizing: unset;
    display: inline-block;
    height: 16px;
    pointer-events: none;
    width: 16px;
    mask-size: 16px;
    top: 4px;
    position: relative;
}


.white-list-domain {
    background: #f1f3f4;
    padding: 0px 8px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: black;
    min-width: 58px;
    display: inline-block;
    text-align: center;
    margin: 0px 2px 4px;
    position: relative;
}


.white-list-domain span {
    margin-left: 10px;
}

.white-list-domain i {
    mask-size: 12px !important;
    -webkit-mask-size: 12px !important;
}

#tab-store-list .ladiui-select-options {
    min-width: 150px;
}

.store-resource-limit .ladiui.form-group {
    color: #003cfc;
}