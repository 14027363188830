.react-datepicker {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    font-family: inherit;
    border:0px;
}
.react-datepicker__time-container{
    border-left: 1px solid #eaedf0;
}
.react-datepicker__header {
    background-color: #fff;
    padding-top: 12px;
    border-bottom: 1px solid #ebedf2;
}

.react-datepicker__day--selected, .react-datepicker__day--in-range:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--selected:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--selected:hover {
    background-color: #1a73e8;
    color:#fff!important;
    border-radius: 4px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    border-bottom-color: #fff;
}

.react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name {
    color: #000;
    width: 30px;
    margin: 3px;
}

.react-datepicker__navigation{
    top:13px;
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container {
    width: 100%;
}
.ladi-date-picker-icon {
    float: right;
    margin-right: 6px;
    margin-top: -28px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.react-datepicker__day{
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.react-datepicker__current-month{
    font-weight: 500;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    padding:inherit;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: #1a73e8;
    color: #fff!important;
    font-weight: initial;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    font-weight: 500;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before{
    border-top-color:inherit;
}

.react-datepicker-popper{
    z-index: 6;
}

.react-datepicker__month .react-datepicker__month-text{
    display: inline-block;
    width: 4rem;
    margin: 2px;
    height: 2rem;
    padding-top: 10px;
    text-align: center;
}